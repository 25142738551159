<template>
  <div class='add_person'>
    <el-button @click="$router.go(-1)">返回</el-button>
    <div class="explain">
      <p>尊敬的<span> {{typeName}} </span>你好，依据平台设定，您具有以下账号管理权限：</p>
      <p>创建各饮水<span> 单位管理员 </span>，以添加他们获得水量预警信息、设备掉线信息。 他们将具有登录平台使用完整的<span> 流量监控 </span>模块、 完整的<span> 设备管理 </span>模块、完整的<span> 查询管理 </span>模块、部分的<span> 账号管理 </span>模块的权限，单位管理员 可创建<span> 产品维护员 </span>账号， 产品维护员可以使用流程监控的<span> 流量告警 </span>功能、 设备管理的<span> 设备在线查询 </span>功能，单位管理员与产品维护员在平台上的所有操作将计入<span> 系统日志 </span>。</p>
    </div>
    <div class="userForm">
      <el-form :model="userForm" :rules="rules" label-width="120px" ref="userForm">
        <el-form-item prop="roleId" label="账号类型：">
          <el-select v-model="userForm.roleId" placeholder="请选择账号类型" size="small">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolId" label="所属单位：">
          <el-select v-model="userForm.schoolId" placeholder="请选择所属单位" size="small">
            <el-option v-for="item in units" :key="item.id" :label="item.label?item.label:item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="realname" label="姓名： ">
          <el-input v-model="userForm.realname" placeholder="请输入姓名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机： ">
          <el-input v-model="userForm.phone" placeholder="请输入您的手机号" size="small">
          </el-input>
        </el-form-item>
        <el-form-item label="邮箱： ">
          <el-input v-model="userForm.email" placeholder="请输入您的邮箱" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="username" label="用户名： ">
          <el-input v-model="userForm.username" placeholder="请输入用户名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="初始密码： ">
          <el-input v-model="userForm.password" placeholder="请输入初始密码" size="small">
          </el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="userForm.info" type="textarea" placeholder="请输入备注（选填）" size="small">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loginLoading" @click="submitForm('userForm')">提交</el-button>
          <el-button @click="resetForm('userForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addPerson, getSupplierList } from '@/api'
export default {
  data () {
    return {
      userType: [],
      userForm: {
        roleId: '',
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: '',
        realname: '',
        phone: '',
        email: '',
        username: '',
        password: '',
        info: ''
      },
      rules: {
        realname: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: this.$validator.phone, trigger: 'blur' }
        ],
        username: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: this.$validator.password, trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '请选择用户类型', trigger: 'change' }
        ],
        schoolId: [
          { required: true, message: '请选择所属单位', trigger: 'change' }
        ]
      },
      loginLoading: false,
      cityList: [],
      oldList: [],
      nameList: [],
      supplierList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['typeList', 'unitList', 'type', 'realname']),
    typeName () {
      const name = this.typeList.filter(el => el.id === this.type)[0].name
      return name
    },
    units () {
      let arr = []
      if (this.userForm.roleId === 2) {
        arr = this.supplierList
      } else {
        arr = this.unitList
      }
      return arr
    }
  },
  methods: {
    // 获取供应商列表
    async init () {
      this.supplierList = await getSupplierList()
    },
    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          // console.log('valid :>> ', valid);
          if (this.userForm.roleId === 2) {
            this.userForm.supplierId = this.userForm.schoolId
            delete this.userForm.schoolId
          }
          addPerson(this.userForm).then(() => {
            this.$message.success('添加人员成功！')
            this.$add(
              {
                userName: this.realname,
                module: '人员管理',
                content: `${this.realname}添加了${this.userForm.realname}`,
                type: 3,
                platform: 8,
                operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
              }
            )
            this.$router.push('/system/personnel')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.add_person {
  position: relative;
  > .el-button {
    position: absolute;
    right: 0;
    top: -20px;
  }
  .explain {
    width: 80%;
    border: 1px solid #1890ff;
    border-radius: 5px;
    margin: 20px auto 40px;
    padding: 10px;
    font-size: 12px;
    p {
      line-height: 1.5;
      span {
        color: rgb(24, 144, 255);
      }
    }
    p:last-child {
      text-indent: 24px;
    }
  }
}
.userForm {
  ::v-deep .el-form {
    width: 55%;
    margin: 0 auto;
    .el-form-item__content {
      text-align: right;
      .is-disabled {
        .el-input__inner {
          border: none;
        }
      }
      .el-select {
        width: 100%;
      }
      .el-textarea {
        .el-textarea__inner {
          background: none;
          // border: 1px solid #30c6fe;
          // color: #fff;
        }
      }
    }
  }
}
</style>
